@import '@/styles/mixins/_breakpoints.scss';
@import '@/styles/variables/_breakpoints.scss';
@import '@/styles/variables/_colors.scss';

:global(.know-your-fish-drawer) {
    .article__card {
        &.fixed {
            min-height: 432px;
            .article__card-img {
                height: 312px;

                @include breakpoint-up('sm') {
                    height: 384px;
                }
            }
        }
    }
}

.article__card {
    background-color: #fff;
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
    height: 100%;
    border: 1px solid $fawn50-2;
    width: inherit;
    min-width: 275px;

    @include breakpoint-up('sm') {
        padding: 24px;
    }

    &:hover {
        :global(.heading--article) {
            color: $salmon;
        }
    }

    &.fixed {
        min-height: 432px;

        @include breakpoint-up('sm') {
            min-height: 576px;
        }

        .article__card-img {
            height: 262px;

            @include breakpoint-up('sm') {
                height: 384px;
            }
        }
    }

    // article__card-img
    &-img {
        position: relative;

        img {
            border-radius: 6px;
        }

        .bookmark {
            position: absolute;
            background-color: #fff;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            top: 12px;
            right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint-up('sm') {
                width: 48px;
                height: 48px;
            }

            svg {
                width: 15.24px;
                height: 23.14px;
            }
        }
    }

    // article__card-content
    &-content {
        margin-top: 24px;

        .recipe--time {
            font-size: fontsize(12px);
            line-height: 120%;
            font-weight: 500;
            color: $salmon;

            @include breakpoint-up('sm') {
                font-size: fontsize(14px);
            }
        }

        span {
            margin-bottom: 12px;
            display: inline-block;
        }

        h1 {
            margin-top: 6px;
        }
    }
}