@import '@/styles/_shared.scss';

:global(.featured-articles) {
    .article-card {
        &.fixed {
            .image-wrap {
                @include breakpoint-up('md') {
                    height: 499px;
                }
            }
        }
        :global(.heading--article) {
            @include breakpoint-up('md') {
                font-size: 48px;
            }
        }
        :global(.recipe--time) {
            @include breakpoint-up('md') {
                font-size: 18px;
            }
        }
    }
}

.article-card {
    background-color: #fff;
    text-decoration: none;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    border: 1px solid $fawn50-2;

    &:not(.btn):hover {
        text-decoration: none;
    }

    &.fixed {
        .image-wrap {
            position: relative;
            height: 262px;

            @include breakpoint-up('sm') {
                height: 384px;
            }
        }
    }

    .slider__slide {
        position: relative;
        padding: 17.94px 15px;
        border: 1px solid $fawn50-2;
        height: 100%;
        border-radius: 12px;

        &.row {
            @include breakpoint-up('md') {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 0;
                padding: 24px;
            }

            .image-wrap {
                position: relative;

                @include breakpoint-up('md') {
                    min-width: 448px;
                    width: 48%;
                }
            }

            .text {
                @include breakpoint-up('md') {
                    width: 48%;
                    padding-left: 24px;
                }
            }
        }

        .image-wrap {
            position: relative;
        }

        .play {
            background-color: $alabaster;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 40px;
                height: 40px;
                @include breakpoint-up('md') {
                    width: 69px;
                    height: 69px;
                }
            }

            svg path {
                fill: $sitkablue;
            }
        }

        img {
            border-radius: 6px;
        }

        .text {
            margin-top: 17.94px;

            h4 {
                margin-bottom: 12px;
            }

            p {
                color: $salmon;
                display: flex;
                align-items: center;
            }

            .btn-wrap {
                width: 145px;
                margin: auto;

                a {
                    min-width: 0;
                }
            }

            .recipe--time {
                display: flex;
            }

            .icon {
                width: 23px;
                margin-left: 13px;
                margin-top: 2px;
            }

            .search-cta {
                font-weight: 500;
                color: $salmon;
                text-decoration: none;

                svg {
                    width: 23px;
                    margin-left: 8px;

                    path {
                        fill: $sitkablue;
                    }
                }
            }
        }
    }
}