@import '@/styles/_shared.scss';

.articles {
  position: relative;
  background-color: #b7cd96;
  padding: 60px 0 72px 0;
  overflow: hidden;

  @include breakpoint-up('sm') {
    padding: 144px 0;
  }

  .illustration-1 {
    position: absolute;
    top: 108px;
    left: -120px;
    display: none;
    width: 366px;

    @include breakpoint-up('md') {
      display: block;
    }

    @include breakpoint-up('lg') {
      width: 366px;
    }

    @include breakpoint-up('xl') {
      width: 570px;
    }
  }

  .illustration-2 {
    position: absolute;
    bottom: -36px;
    right: 0;
    display: none;

    @include breakpoint-up('md') {
      display: block;
      width: 366px;
    }

    @include breakpoint-up('lg') {
      width: 366px;
    }

    @include breakpoint-up('xl') {
      width: 570px;
    }
  }

  &__content {
    max-width: 1680px;
    margin: auto;
    width: 100%;

    @include breakpoint-up('sm') {
      padding-left: 0;
    }
  }

  // articles__tab-swiper
  &__tabs-swiper {
    min-height: 32px;
    padding-right: 30px;

    @include breakpoint-up('sm') {
      text-align: center;
      min-height: 45px;
      display: flex;
      justify-content: center;
      padding-bottom: 25px;
    }

    @include breakpoint-up('sm') {
      padding-left: 50px;
    }

    @include breakpoint-up('md') {
      padding-right: 60px;
    }

    @include breakpoint-up('lg') {
      padding-right: 85px;
    }

    @include breakpoint-up('xl') {
      padding-right: 120px;
    }

    :global(.swiper-wrapper) {
      @include breakpoint-up('md') {
        justify-content: center;
      }
    }

    .tab-slide {
      min-width: 59px;
      display: flex;
      align-items: center;
      width: auto;
      // margin-right: 75px;

      @include breakpoint-up('sm') {
        width: initial;
        justify-content: center;
      }

      &:first-child {
        margin-left: 30px;

        @include breakpoint-up('sm') {
          margin-left: 40px;
        }

        @include breakpoint-up('md') {
          margin-left: 60px;
        }

        @include breakpoint-up('lg') {
          margin-left: 85px;
        }

        @include breakpoint-up('xl') {
          margin-left: 120px;
        }
      }
    }

    button {
      line-height: 140%;
      font-family: $frank-ruhl;
      font-weight: 500;
      height: 100%;
      border: none;
      background: transparent;
      padding: 0;

      &::after {
        content: '';
        position: absolute;
        border-bottom: 3px solid transparent;
        width: 100%;
        left: 0;
        bottom: -10px;
      }

      &.active {
        font-weight: 500;
        font-family: $frank-ruhl;
        position: relative;

        &::after {
          border-bottom: 3px solid $sitkablue;
        }
      }
    }
  }

  // articles__swiper
  &__swiper {
    margin-top: 30px;
    padding-right: 30px;

    @include breakpoint-up('sm') {
      margin-top: 47px;
      padding-right: 50px;
    }

    @include breakpoint-up('md') {
      padding-right: 60px;
    }

    @include breakpoint-up('lg') {
      padding-right: 85px;
    }

    @include breakpoint-up('xl') {
      padding-right: 120px;
    }

    .article-slide {
      width: 263px;

      @include breakpoint-up('sm') {
        width: 393px;
      }

      &:first-child {
        margin-left: 30px;

        @include breakpoint-up('sm') {
          margin-left: 50px;
        }

        @include breakpoint-up('md') {
          margin-right: 60px;
        }

        @include breakpoint-up('lg') {
          margin-right: 85px;
        }

        @include breakpoint-up('xl') {
          margin-right: 120px;
        }
      }
    }
  }

  // articles__image
  &__image {
    max-width: 532px;
    width: 100vw;
    z-index: -1;

    img {
      object-fit: cover;
    }
  }

  // articles__header
  &__header {
    text-align: center;
    padding: 0 28px;
    max-width: 822px;
    margin: auto;

    h1 {
      margin-bottom: 12px;
    }

    h2 {
      margin-bottom: 36px;

      @include breakpoint-up('sm') {
        margin-bottom: 60px;
      }
    }
  }

  // articles__btn
  &__btn {
    width: 243px;
    min-width: 0;
    margin: 36px auto 0 auto;
    background-color: $salmon;
    color: $fawn50-2;

    @include breakpoint-up('sm') {
      margin: 60px auto 0 auto;
      width: 393px;
    }
  }
}
